//
import GUserAccountService from '@/services/api/General/GUserAccount'
import PProviderService from '@/services/api/Provider/PProvider'
import McMiningCompanyService from '@/services/api/MiningCompany/McMiningCompany'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-account-categories',
  data() {
    return {
      busy: {
        create: false,
        enable: false,
        disable: false,
        listed: false,
        unblock: false,
      },
      dialogs: {
        create: false,
        enable: false,
        disable: false,
        unblock: false,
      },
      filters: {
        gUserAccountId: null,
        limit: 15,
        page: 1,
      },
      gUserAccountEnable: {},
      gUserAccountDisable: {},
      gUserAccountUnblock: {},
      gUserAccounts: {
        data: [],
        last_page: 1,
      },
      mcMiningCompanyUser: {},
      mcMiningCompanies: [],
    }
  },
  methods: {

    // /**
    //  * 
    //  */
    // getMiningCompanies() {
    //   McMiningCompanyService.getByMiningCompany(1).then(res => {
    //   // McMiningCompanyService.getAllPaginate().then(res => {
    //     this.mcMiningCompanies = res.data
    //   })
    // },

    /**
     * 
     */
    handleCreateReset() {
      this.mcMiningCompanyUser = McMiningCompanyService.toUser()
      this.mcMiningCompanyUser.miningId = this.$user.profile.relatedId
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await McMiningCompanyService.newUser(this.mcMiningCompanyUser).then(this.handleCreateSuccess).catch(this.handleError)
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(response) {
      this.handleReset()
      McMiningCompanyService.translate({ response }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleEnableClick(gUserAccount) {
      this.dialogs.enable      = true
      this.gUserAccountEnable  = gUserAccount
    },

    /**
     * 
     */
    async handleEnableSubmit() {
      this.busy.enable = true
      await GUserAccountService.enable(this.gUserAccountEnable).then(this.handleEnableSuccess).catch(this.handleError)
      this.busy.enable = false
    },

    /**
     * 
     */
    handleEnableSuccess(payload) {
      this.handleReset()
      GUserAccountService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.enable  = false
    },

    /**
     * 
     */
    handleDisableClick(gUserAccount) {
      this.dialogs.disable      = true
      this.gUserAccountDisable  = gUserAccount
    },

    /**
     * 
     */
    async handleDisableSubmit() {
      this.busy.disable = true
      await GUserAccountService.disable(this.gUserAccountDisable).then(this.handleDisableSuccess).catch(this.handleError)
      this.busy.disable = false
    },

    /**
     * 
     */
    handleDisableSuccess() {
      this.handleReset()
      this.dialogs.disable = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.gUserAccounts = await GUserAccountService.getBy(this.filters)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleError(payload) {
      GUserAccountService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleUnblockSubmit() {
      this.busy.unblock = true
      await PProviderService.unblockSurvey(this.gUserAccountUnblock.id).then(this.handleUnblockSuccess).catch(this.handleError)
      this.busy.unblock = false
    },

    /**
     * 
     * @param {*} gUserAccount 
     */
    handleUnblockSuccess() {
      this.handleReset()
      this.dialogs.unblock = false
    },

    /**
     * 
     */
    handleUnblockSurveyClick(gUserAccount) {
      this.dialogs.unblock      = true
      this.gUserAccountUnblock  = gUserAccount
    },
  },
  mounted() {
    this.filters.gUserAccountId = this.$user.user
    this.handleReset()
    this.handleCreateReset()
    // this.getMiningCompanies()
  },
  props: {

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(1),
        RulesService.max(180),
      ],
    },

    /**
     * 
     */
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.min(0),
        RulesService.max(40),
      ],
    },

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    },

    /**
     * 
     */
    'filters.limit'() {
      this.handleReset()
    },
  },
}